import BasePlugin from '../BasePlugin'

export default class ApproveAdjustmentRequestCommand extends BasePlugin {
  async execute () {
    let adjustmentRequestId = this.context.getModel().id
    let me = this
    let adjustmentRequestData = {
      'adjustmentRequestId': adjustmentRequestId,
      'actionTypeId': 3
    }

    let datesResponse = await this.context.$http.get(
      `${this.context.$config.api}/registryservice/plugins/execute/CheckAdjustmentRequestAndInvestmentRequestDatesQuery?adjustmentRequestId=${this.context.getCard().data.id}`
    )

    if (!datesResponse.data.is_equal_dates) {
      let message = `<p> Проектные сроки отличаются от текущих (сроки инвестзаявки: ${datesResponse.data.investment_request_start_date} - ${datesResponse.data.investment_request_end_date} , новые сроки: ${datesResponse.data.scenario_start_date} - ${datesResponse.data.scenario_end_date} ).Вы действительно хотите утвердить новые сроки?<br>`
      this.context.$msgbox({
        type: 'info',
        confirmButtonText: 'Да',
        showCancelButton: true,
        center: true,
        message: message,
        dangerouslyUseHTMLString: true,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            this.checkAndSetAdjustmentRequestRule(me, adjustmentRequestData)
            done()
          } else {
            done()
          }
        }
      })
    } else {
      this.checkAndSetAdjustmentRequestRule(me, adjustmentRequestData)
    }
  }

  checkAndSetAdjustmentRequestRule (me, adjustmentRequestData) {
    me.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/CheckAdjustmentRequestRuleCommand`,
      adjustmentRequestData,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then((response) => {
      if (response.data.rule_id) {
        this.context.getCard().setLoading(false)
        if (response.data.is_require_additional_confirmation_from_user) {
          me.context.$msgbox({
            type: 'info',
            confirmButtonText: 'Да',
            showCancelButton: true,
            center: true,
            message: response.data.message,
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/AddApprovalHistoryRecordByAdjustmentRequestTypeCommand`,
                  { 'adjustmentRequestId': this.context.getCard().data.id, 'action': 'approve' },
                  {
                    headers: {
                      'Content-Type': 'application/json'
                    }
                  }
                )
                me.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/SetAdjustmentRequestRuleCommand`,
                  adjustmentRequestData,
                  {
                    headers: {
                      'Content-Type': 'application/json'
                    }
                  }
                ).then((response) => {
                  if (response.data.is_base_scenario) {
                    me.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/SetInvestmentRequestBaseScenarioAsyncCommand`,
                      {
                        'scenarioId': response.data.scenario_id,
                        'async': 'true'
                      },
                      {
                        headers: {
                          'Content-Type': 'application/json'
                        }
                      })
                  }
                })
                done()
              } else {
                done()
                this.context.$msgbox().close()
              }
            }
          })
        } else {
          this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/AddApprovalHistoryRecordByAdjustmentRequestTypeCommand`,
            { 'adjustmentRequestId': this.context.getCard().data.id, 'action': 'approve' },
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          )
          me.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/SetAdjustmentRequestRuleCommand`,
            adjustmentRequestData,
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          ).then((response) => {
            if (response.data.message) {
              me.context.$msgbox({
                type: 'info',
                message: response.data.message
              })
            }
            if (response.data.is_base_scenario) {
              me.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/SetInvestmentRequestBaseScenarioAsyncCommand`,
                {
                  'scenarioId': response.data.scenario_id,
                  'async': 'true'
                },
                {
                  headers: {
                    'Content-Type': 'application/json'
                  }
                })
            }
          })
        }
      } else {
        this.context.getCard().setLoading(false)
        me.context.$msgbox({
          type: 'info',
          message: 'Действие не было выполнено. Информация в корректировке не соответствует ни одному из существующих правил репликации статусов целевого сценария'
        })
      }
    })
    this.context.getCard().$emit('cancelChanges')
  }
}
